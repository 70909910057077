/* Testimonial Section */
.testimonial-area {
    background: #ffffff; /* White background for a clean look */
    padding: 100px 0;
  }
  
  /* Image and Text Container */
  .testimonial-img-wrapper {
    text-align: center;
  }
  
  .image-text-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px; /* Space between image and text */
    font-family: 'Nunito', sans-serif;
  }
  
  .testimonial-logo {
    width: 120px; /* Larger image size */
    height: 120px;

    border-radius: 10px;
  }
  
  .textlink-heading {
    font-size: 2.5rem;
    color: #333;
    font-weight: 600;
    margin: 0;
    font-family: 'Nunito', sans-serif;

  }
  
  /* Text Wrapper */
  .testimonial-text-wrapper {
    padding-left: 30px;
  }
  
  .section-title .style-text {
    font-size: 1.5rem;
    color: #007bff; /* Brand color */
    margin-bottom: 10px;
  }
  
  .section-title .sect-title {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .paragraph-title {
    font-size: 1.2rem;
    color: #666;
    line-height: 1.6;
    margin-bottom: 30px;
  }
  
  .paragraph-title strong {
    color: #333;
    font-weight: 600;
  }
  
  /* CTA Button */
  .cta-wrapper {
    margin-top: 30px;
  }
  
  .cta-button {
    display: inline-block;
    padding: 15px 30px;
    font-size: 1rem;
    font-weight: 600;
    color: #fff;
    background-color: #007bff; /* Brand color */
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #0056b3; /* Darker shade for hover */
    transform: translateY(-3px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }