/***********************************************
  GLOBAL / WRAPPER
***********************************************/
.ghl-sms-integration-wrapper {
    position: relative; /* Added for back button positioning */
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px; /* Constrains content width for better readability */
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    font-family: "Inter", Arial, sans-serif;
    color: #2d2d2d;
  }
  
  /***********************************************
    BACK BUTTON
  ***********************************************/
  .ghl-back-button {
    position: absolute;
    top: 20px;
    left: 20px;
  }
  
  .ghl-back-button a {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 1.1rem;
    color: #2d2d2d;
    background-color: #ffd26f;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    transition: transform 0.3s;
  }
  
  .ghl-back-button a:hover {
    transform: scale(1.05);
  }
  
  .ghl-back-icon {
    margin-right: 5px;
    font-size: 1.2rem;
  }
  
  /***********************************************
    HERO / TITLE SECTION
  ***********************************************/
  .ghl-title-div {
    width: 100%;
    text-align: center;
    color: #fff;
    padding: 60px 20px;
    border-radius: 8px;
    margin-bottom: 40px;
  }
  
  .ghl-title-div h1 {
    font-size: 2.4rem;
    font-weight: 700;
    margin-bottom: 1rem;
    line-height: 1.2;
  }
  
  .ghl-hero-subtitle {
    font-size: 1.1rem;
    margin-bottom: 2rem;
    max-width: 700px;
    margin: 0 auto 2rem auto;
    line-height: 1.6;
  }
  
  /***********************************************
    CONTENT ROWS
  ***********************************************/
  .ghl-content-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px 0;
    box-sizing: border-box;
    gap: 30px; /* space between columns */
    margin-bottom: 40px;
  }
  
  /* Reverse order for sections if needed */
  .ghl-content-row.reverse {
    flex-direction: row-reverse;
  }
  
  /***********************************************
    VIDEO COLUMN
  ***********************************************/
  .ghl-video-column {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fafafa;
    border-radius: 8px;
    padding: 10px;
    box-sizing: border-box;
    min-height: 300px;
  }
  
  .ghl-video-column iframe {
    width: 100%;
    height: auto;
    border: none;
    border-radius: 8px;
    min-height: 300px;
  }
  
  /***********************************************
    TEXT COLUMN
  ***********************************************/
  .ghl-text-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
    box-sizing: border-box;
  }
  
  .ghl-section-title {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    font-weight: 700;
  }
  
  .ghl-text-paragraph {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1.2rem;
  }
  
  /* Optional highlight for special text */
  .ghl-highlight {
    color: #6f42c1;
    font-weight: 600;
  }
  
  /***********************************************
    STATS (small boxes with icons)
  ***********************************************/
  .ghl-content-stats {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  
  .ghl-stat {
    flex: 1;
    display: flex;
    align-items: center;
    background-color: #efefef;
    border-radius: 6px;
    padding: 10px 15px;
    font-weight: 600;
    box-sizing: border-box;
  }
  
  .ghl-stat p {
    margin: 0;
    font-size: 0.95rem;
    line-height: 1.4;
  }
  
  .ghl-icon {
    margin-right: 8px;
    color: #6f42c1; /* Icon color */
    flex-shrink: 0; /* Keep icon from shrinking */
  }
  
  /***********************************************
    DEVICES & SIMS INFORMATION SECTION
  ***********************************************/
  .ghl-device-sim-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;
    padding: 40px 20px;
    background-color: #eef2f7;
    border-radius: 8px;
    margin-bottom: 40px;
  }
  
  .ghl-device-sim-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
  }
  
  .ghl-icon-large {
    font-size: 3rem;
    color: #6f42c1;
    margin-bottom: 10px;
  }
  
  .ghl-device-sim-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 10px;
  }
  
  /***********************************************
    CTA SECTION
  ***********************************************/
  .ghl-cta-section {
    width: 100%;
    text-align: center;
    padding: 40px 20px;
    margin-top: 20px;
    border-radius: 8px;
  }
  
  .ghl-cta-section h3 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    font-weight: 700;
  }
  
  .ghl-cta-section p {
    font-size: 1rem;
    margin-bottom: 1.5rem;
    line-height: 1.6;
  }
  
  /***********************************************
    BUTTONS
  ***********************************************/
  .ghl-btn-primary,
  .ghl-btn-secondary {
    cursor: pointer;
    border: none;
    border-radius: 4px;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
  }
  
  /* Primary CTA - stands out (bright color or contrasting style) */
  .ghl-btn-primary {
    background: linear-gradient(135deg, #ffd26f 0%, #f8b500 100%);
    color: #2d2d2d;
    padding: 0.75rem 1.5rem;
  }
  
  .ghl-btn-primary:hover {
    transform: scale(1.05);
    box-shadow: 0 5px 15px rgba(248, 181, 0, 0.3);
  }
  
  /* Secondary CTA - complementary color */
  .ghl-btn-secondary {
    background-color: #6f42c1;
    color: #fff;
    padding: 0.75rem 1.5rem;
  }
  
  .ghl-btn-secondary:hover {
    background-color: #5a33a1;
    transform: translateY(-2px);
  }
  
  /* Larger button variant */
  .ghl-btn-large {
    font-size: 1.1rem;
    padding: 1rem 2rem;
  }
  
  /***********************************************
    RESPONSIVE DESIGN
  ***********************************************/
  @media (max-width: 992px) {
    .ghl-title-div h1 {
      font-size: 2rem;
    }
  }
  
  @media (max-width: 768px) {
    .ghl-device-sim-section {
        flex-direction: column;
        align-items: center;
      }
    .ghl-content-row,
    .ghl-content-row.reverse {
      flex-direction: column;
    }
  
    .ghl-title-div h1 {
      font-size: 1.8rem;
    }
  
    .ghl-btn-large {
      font-size: 1rem;
      padding: 0.8rem 1.5rem;
    }
  }
  
  @media (max-width: 480px) {
    .ghl-title-div h1 {
      font-size: 1.5rem;
    }
  
    .ghl-hero-subtitle {
      font-size: 0.95rem;
    }
  
    .ghl-section-title {
      font-size: 1.4rem;
    }
  
    .ghl-content-row {
      gap: 20px;
    }
  }
  