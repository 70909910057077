/* About Section */
.about-us-area {
    background: #f9f9f9; /* Light background for contrast */
    padding: 80px 0;
}

.about-text-wrapper .sect-title {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
}

.quote-paragraph {
    margin-bottom: 30px;
}

.quote-paragraph h3 {
    font-size: 1.5rem;
    color: #007bff; /* Brand color */
    margin-bottom: 10px;
}

.quote-paragraph p {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
}

/* Video Section */
.about-video-wrapper {
    text-align: center;
}

/* Responsive Video Container */
.video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 50px;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}
.video-container3 {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 50px;
}

.video-container3 iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
  
  /* Work Process Section */
  .work-process-area {
    background: #fff;
    padding: 80px 0;
    margin-top: 20px;
  }
  
  .process-bg {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
  }
  
  .process-bg li {
    flex: 1;
    margin: 0 10px;
    text-align: center;
  }
  
  .work-process {
    padding: 20px;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    height: 150px;
  }
  
  .work-process:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .process-icon img {
    width: 60px;
    height: 60px;
    margin-bottom: 15px;
  }
  
  .work-process h6 {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .work-process p {
    font-size: 0.9rem;
    color: #666;
  }