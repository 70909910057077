.affiliate-analytics {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .affiliate-metric {
    text-align: left;
    padding: 15px;
    border-radius: 8px;
    background-color: #f8f9fa;
    flex: 1;
    margin: 0 10px;
  }
  
  .metric-label {
    font-size: 14px;
    color: #6c757d;
    margin-bottom: 5px;
  }
  
  .metric-value {
    font-size: 15px; /* Reduced font size for better proportion */
    font-weight: bold;
    color: #000;
    margin:10px;
  }
  
  .affiliate-chart {
    margin-top: 20px;
  }
  
  .affiliate-code-section {
    text-align: left; /* Align content to the left */
  }
  
  .affiliate-explanation ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .card-title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  
  .btn-dim {
    background-color: #6576ff;
    border-color: #6576ff;
    color: #fff;
  }
  
  .btn-dim:hover {
    background-color: #4e5dff;
    border-color: #4e5dff;
  }
  
  .d-flex {
    display: flex;
  }
  
  .gap-2 {
    gap: 8px;
  }

  .steps-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
  }
  
  .step-item {
    display: flex;
    align-items: flex-start;
    gap: 15px;
    padding: 15px;
    border-radius: 8px;
    background-color: #f8f9fa;
    transition: background-color 0.3s ease;
  }
  
  .step-item:hover {
    background-color: #e9ecef;
  }
  
  .step-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #6576ff;
    color: #fff;
    font-size: 18px;
  }
  
  .step-content {
    flex: 1;
  }
  
  .step-content h6 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
    color: #000;
  }
  
  .step-content p {
    font-size: 14px;
    color: #6c757d;
    margin: 0;
  }