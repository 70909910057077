/* Sticky Back Button */
.back-button {
    position: fixed; /* Makes the button sticky */
    top: 20px;
    left: 20px;
    background-color: #000;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    z-index: 1000; /* Ensures the button stays on top of other content */
  }
  
  .back-button:hover {
    background-color: #333;
  }
  
  /* General Styles */
  .ghl-integration-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    color: #333;
    position: relative;
    padding-top: 70px; /* Added padding to prevent content from being hidden behind the sticky button */
  }
  
  /* Page Title */
  .page-title {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #2c3e50;
  }
  
  /* Explanation Text */
  .explanation-text {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
    color: #555;
  }
  
  /* Reminder Box */
  .reminder-box {
    background-color: #fff3cd;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
    border-left: 4px solid #ffc107;
  }
  
  .reminder-text {
    font-size: 14px;
    color: #856404;
    margin: 0;
  }
  
  /* Step-by-Step Guide */
  .guide-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #2c3e50;
  }
  
  .steps-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .step-item {
    display: flex;
    align-items: flex-start;
    gap: 15px;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
  }
  
  .step-number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: #007bff;
    color: #fff;
    border-radius: 50%;
    font-size: 16px;
    font-weight: 600;
    flex-shrink: 0;
  }
  
  .step-content {
    flex: 1;
  }
  
  .step-content p {
    margin: 0;
    font-size: 16px;
    line-height: 1.6;
    color: #555;
  }
  
  /* Instruction Detail */
  .instruction-detail {
    margin-top: 10px;
    font-size: 14px;
    color: #666;
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 4px;
    border-left: 3px solid #007bff;
  }
  
  /* GHL Login Reminder */
  .ghl-login-reminder {
    background-color: #e9f5ff;
    padding: 10px;
    border-radius: 4px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-left: 3px solid #007bff;
  }
  
  .ghl-login-reminder p {
    margin: 0;
    font-size: 14px;
    color: #007bff;
  }
  
  /* Connect Button */
  .connect-button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .connect-button:hover {
    background-color: #0056b3;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .back-button {
      top: 10px;
      left: 10px;
      font-size: 12px;
      padding: 8px 12px;
    }
  
    .ghl-integration-container {
      padding-top: 60px; /* Adjusted for smaller screens */
    }
  
    .page-title {
      font-size: 24px;
    }
  
    .explanation-text {
      font-size: 14px;
    }
  
    .step-item {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  
    .step-number {
      width: 25px;
      height: 25px;
      font-size: 14px;
    }
  
    .step-content p {
      font-size: 14px;
    }
  
    .instruction-detail {
      font-size: 12px;
    }
  
    .connect-button {
      font-size: 14px;
      padding: 8px 16px;
    }
  }