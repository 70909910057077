/* General Styles */
.affiliate-program {
    font-family: "Arial", sans-serif;
    color: #333;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Description Section */
  .description {
    text-align: center;
    padding: 60px 20px;
    border-radius: 10px;
    margin-bottom: 40px;
  }
  
  .description h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .description p {
    font-size: 1.2rem;
    color: #666;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .description strong {
    color: #007bff; /* Highlight key text with a blue color */
    font-weight: bold;
  }
  
  /* Step-by-Step Section */
  .steps {
    display: flex;
    flex-direction: row; /* Align cards horizontally */
    gap: 20px; /* Spacing between cards */
    margin-bottom: 40px;
    overflow-x: auto; /* Enable horizontal scrolling on small screens */
    padding-bottom: 20px; /* Add space for scrollbar */
  }
  
  .step-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 30px;
    background-color: #fff;
    border: 1px solid #e0e0e0; /* Light border for a clean look */
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    min-width: 300px; /* Minimum width for each card */
    flex: 1 1 auto; /* Allow cards to grow and shrink */
  }
  
  .step-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  }
  
  .step-number {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #007bff;
    color: #fff;
    border-radius: 50%;
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .step-content h2 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #333;
  }
  
  .step-content p {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
  }
  
  /* CTA Section */
  .cta {
    text-align: center;
    padding: 60px 20px;
    background-color: #f9f9f9; /* Light background for minimalistic look */
    border-radius: 12px;
    margin-top: 40px;
  }
  
  .cta h2 {
    font-size: 2rem;
    margin-bottom: 15px;
    color: #333;
  }
  
  .cta p {
    font-size: 1.1rem;
    color: #666;
    margin-bottom: 25px;
  }
  
  /* CTA Button */
  .cta-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 12px 30px;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff; /* Primary blue color */
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
    transform: translateY(-2px); /* Slight lift effect */
  }
  
  .cta-button:active {
    transform: translateY(0); /* Reset lift effect on click */
  }
  
  .cta-button span {
    margin-right: 10px; /* Space between text and icon */
  }
  
  .cta-icon {
    width: 20px;
    height: 20px;
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .steps {
      flex-wrap: wrap; /* Allow cards to wrap on smaller screens */
      justify-content: center; /* Center cards when they wrap */
    }
  
    .step-card {
      min-width: 250px; /* Smaller minimum width for tablets */
    }
  }
  
  @media (max-width: 768px) {
    .steps {
      flex-direction: column; /* Stack cards vertically on mobile */
      align-items: center; /* Center cards vertically */
    }
  
    .step-card {
      width: 100%; /* Full width for mobile */
      max-width: 400px; /* Limit card width for better readability */
    }
  
    .description h1 {
      font-size: 2rem;
    }
  
    .description p {
      font-size: 1rem;
    }
  
    .cta h2 {
      font-size: 1.75rem;
    }
  
    .cta p {
      font-size: 1rem;
    }
  }