.stats-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
    gap: 20px;
}

.stat-item {
    text-align: center;
    padding: 20px;
    background: white;
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.stat-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.stat-number {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 10px;
}

.stat-label {
    font-size: 1rem;
    color: #666;
}
.benefit-card {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.benefit-icon {
    font-size: 2rem;
    margin-right: 20px;
    color: #007bff; /* Brand color */
}

.benefit-content h3 {
    margin-bottom: 10px;
    font-size: 1.5rem;
    color: #333;
}

.benefit-content p {
    font-size: 1rem;
    color: #666;
}