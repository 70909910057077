/* Global Container */
.pricing-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    text-align: center;
  }
  
  /* Toggle Switch */
  .toggle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .toggle-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #5fdd54;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  .toggle-label {
    margin-left: 1rem;
    font-weight: bold;
    color: #333;
  }
  
  /* Pricing Grid */
  .pricing-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 1.5rem;
    justify-content: center;
  }
  
  /* Pricing Card */
  .pricing-card {
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .pricing-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .pricing-card.highlight {
    border: 2px solid #007bff;
  }
  
  /* Card Header */
  .card-header {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
  .plan-name {
    font-size: 1.5rem;
    font-weight: bold;
    color: #007bff;
    margin-bottom: 0.5rem;
  }
  
  .popular-badge {
    display: inline-block;
    background: #000;
    color: #fff;
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    margin-left: 0.5rem;
  }
  
  .price {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  .price .period {
    font-size: 1rem;
    color: #666;
  }
  
  .yearly-price {
    font-size: 0.875rem;
    color: #666;
  }
  
  /* Features List */
  .features-list {
    list-style: none;
    padding: 0;
    margin: 1.5rem 0;
  }
  
  .features-list li {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;
    font-size: 0.875rem;
    color: #333;
  }
  
  .features-list .icon {
    margin-right: 0.5rem;
    font-size: 1rem;
  }
  
  /* CTA Button */
  .cta-button {
    width: 100%;
    padding: 0.75rem;
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .cta-button:hover {
    background: #0056b3;
  }