/* Tool Cards */
.tool-card {
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .tool-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .tool-icon {
    font-size: 2rem;
    margin-right: 20px;
    color: #007bff; /* Brand color */
  }
  
  .tool-content h3 {
    margin-bottom: 10px;
    font-size: 1.5rem;
    color: #333;
  }
  
  .tool-content p {
    font-size: 1rem;
    color: #666;
  }
  
  /* Image Hover Effect */
 /* Image Container */
.image-container {
    margin-bottom: 40px; /* Adjust spacing between images */
    overflow: hidden; /* Ensure images don't overflow */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    transition: box-shadow 0.3s ease-in-out;
  }
  
  .image-container:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
  }
  
  /* Image Styling */
  .img-one {
    width: 100%; /* Ensure images take full width of container */
    height: auto; /* Maintain aspect ratio */
    display: block; /* Remove extra space below images */
  }
  
  /* Hover Zoom Effect */
  .hover-zoom {
    transition: transform 0.3s ease-in-out, border 0.3s ease-in-out;
  }
  
  .hover-zoom:hover {
    transform: scale(1.05); /* Slight zoom effect */
    border: 2px solid #000; /* Add border on hover */
  }
  
  /* Modal Overlay */
  .image-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .image-modal-content {
    max-width: 90%;
    max-height: 90%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }